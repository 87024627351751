import React from 'react';
import styled from 'styled-components';
import Seo from '../components/Seo';

import Container from '../components/Container';
import config from '../config';
import { typography } from '../styles/typography';
import ContactItem from '../components/ContactItem';

const contactSources = [
  {
    label: 'Email',
    url: 'mailto:riley@drnt.com',
  },
  {
    label: 'Github',
    url: 'https://github.com/rdrnt',
  },
  {
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/in/rileydurant/',
  },
];

const Content = styled.div`
  height: 100%;
  width: 100%;
  text-align: left;

  > div {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    > h1 {
      ${typography.h2};
      margin-bottom: ${config.sizes.spacing * 2}px;
    }
  }
`;

const ContactList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    width: auto;
    height: auto;
    display: block;
    margin-bottom: ${config.sizes.spacing / 2}px;
    > a {
      ${typography.h6};
      color: black;
      text-decoration: none;
      font-weight: 300;
      &:hover {
        color: ${config.colors.secondary};
      }
    }
  }
`;

function Contact() {
  return (
    <>
      <Seo title="Contact" />
      <Content>
        <Container>
          <h1>Contact</h1>
          <ContactList>
            {contactSources.map((source) => (
              <ContactItem
                key={source.label}
                link={source.url}
                title={source.label}
              />
            ))}
          </ContactList>
        </Container>
      </Content>
    </>
  );
}

export default Contact;
