import React from 'react';
import styled from 'styled-components';

import config from '../../config';
import Icon from '../Icon';

import { typography } from '../../styles/typography';

const StyledLinkButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  border: 2px solid ${config.colors.text};
  border-radius: 25px;
  padding: ${config.sizes.spacing}px;
  text-decoration: none;
  color: ${config.colors.text};

  &:not(:first-child) {
    margin-top: ${config.sizes.spacing / 2}px;
  }

  > h5 {
    margin: 0;
    margin-right: ${config.sizes.spacing / 4}px;
  }

  &:hover {
    background-color: ${config.colors.text};

    > h5 {
      color: ${config.colors.background};
    }

    > svg {
      fill: ${config.colors.background};
    }
  }
`;

const ContactItem = ({ title, link }) => {
  return (
    <StyledLinkButton target="_blank" rel="noopener noreferrer" href={link}>
      <h5>{title}</h5>
      <Icon name="arrowRight" size={15} color="black" />
    </StyledLinkButton>
  );
};

export default ContactItem;
